import ckeditorconfig from "@/libs/ckeditorconfig";

export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "partner",
    pw: 2000,
    ph: 768,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "titleEn",
    label: "field.titleEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "titleKm",
    label: "field.titleKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "required|max:700",
    type: "textarea",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "required|max:700",
    type: "textarea",
  },
  {
    label: "breadcrumb.developerInformation",
    icon: "LayersIcon",
    type: "divider",
  },
  {
    key: "leadLogoId",
    initKey: "leadLogo",
    label: "field.logo",
    type: "async-single-image",
    path: "partner",
    pw: 200,
    ph: 200,
    fullWidth: false,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "leadLink",
    label: "field.link",
    rules: "max:200",
    type: "text",
  },
  {
    key: "leadDescriptionEn",
    label: "field.descriptionEn",
    rules: "max:3000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    key: "leadDescriptionKm",
    label: "field.descriptionKm",
    rules: "max:3000",
    type: "ck-editor",
    config: { ...ckeditorconfig.editorConfig },
    cols: 12,
  },
  {
    label: "breadcrumb.partnerContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.partnerContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.partnerConsultant",
    icon: "ImageIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 200,
    ph: 200,
  },
  {
    key: "partnerConsultants",
    hideLabel: true,
    cols: 12,
  },
  {
    label: "breadcrumb.partnerContractor",
    icon: "ImageIcon",
    type: "divider",
    hasDescriptionDimension: true,
    pw: 200,
    ph: 200,
  },
  {
    key: "partnerContractors",
    hideLabel: true,
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
