<template>
  <div>
    <load-profile></load-profile>
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
            <template #partnerConsultants>
              <div class="mt-2">
                <draggable
                  v-model="data.partnerConsultants"
                  :move="onMoveConsultant"
                  :start="true"
                  :end="true"
                  @change="onDragChangeConsultant"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <div
                      class="list-group-item"
                      v-for="(consultant, idx) in data.partnerConsultants"
                      :key="consultant.id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="`${consultant.id}-${idx}`"
                        :name="$t('field.image')"
                        rules="required"
                      >
                        <n-async-single-image-uploader
                          class="gallery"
                          :key="`image-upload-${idx}-${consultantKey}`"
                          path="partner-consultant"
                          ph="240"
                          pw="240"
                          :disableUploadHistory="true"
                          :disableSpacer="false"
                          :fullWidth="false"
                          :image="consultant.image"
                          v-model="consultant.imageId"
                          @change="onUploadChangeConsultant($event, idx)"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-async-single-image-uploader>
                        <feather-icon
                          @click="removeConsultant(idx)"
                          class="close-icon"
                          icon="XIcon"
                          size="18"
                        ></feather-icon>
                      </validation-provider>
                    </div>
                  </transition-group>
                </draggable>
                <hr v-show="data.partnerConsultants.length >= 1" />
                <b-button
                  variant="primary"
                  @click="addMoreConsultant"
                  class="mb-2"
                >
                  <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                  <span>{{ $t("button.addGallery") }}</span>
                </b-button>
              </div>
            </template>
            
            <template #partnerContractors>
              <div class="mt-2">
                <draggable
                  v-model="data.partnerContractors"
                  :move="onMoveContractor"
                  :start="true"
                  :end="true"
                  @change="onDragChangeContractor"
                >
                  <transition-group type="transition" :name="'flip-list'">
                    <div
                      class="list-group-item"
                      v-for="(contractor, idx) in data.partnerContractors"
                      :key="contractor.id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="`${contractor.id}-${idx}`"
                        :name="$t('field.image')"
                        rules="required"
                      >
                        <n-async-single-image-uploader
                          class="gallery"
                          :key="`image-upload-${idx}-${contractorKey}`"
                          path="partner-contractor"
                          ph="240"
                          pw="240"
                          :disableUploadHistory="true"
                          :disableSpacer="false"
                          :fullWidth="false"
                          :image="contractor.image"
                          v-model="contractor.imageId"
                          @change="onUploadChangeContractor($event, idx)"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        ></n-async-single-image-uploader>
                        <feather-icon
                          @click="removeContractor(idx)"
                          class="close-icon"
                          icon="XIcon"
                          size="18"
                        ></feather-icon>
                      </validation-provider>
                    </div>
                  </transition-group>
                </draggable>
                <hr v-show="data.partnerContractors.length >= 1" />
                <b-button
                  variant="primary"
                  @click="addMoreContractor"
                  class="mb-2"
                >
                  <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
                  <span>{{ $t("button.addGallery") }}</span>
                </b-button>
              </div>
            </template>
          </n-input>

          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-save-option
                ref="btnSubmit"
                @submit="submit"
                @save="save"
                :loading="loading"
                :resource="resource"
                :route="route"
                :hide-create="true"
                v-if="$can('update', resource)"
              ></n-button-save-option>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>
      
<script>
import { BRow, BCol, BCard, BButton, BFormInput } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import FormInput from "./formInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";
import NAsyncSingleImageUploader from "@/components/NAsyncSingleImageUploader";
import Draggable from "vuedraggable";

const PartnerRepository = Repository.get("partner");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    Draggable,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
    NAsyncSingleImageUploader,
  },
  directives: {
    Ripple,
  },
  computed: {},
  data() {
    return {
      consultantKey: 1,
      consultantIndex: 0,
      contractorKey: 1,
      contractorIndex: 0,
      data: {
        bannerId: null,
        titleEn: null,
        titleKm: null,
        descriptionEn: null,
        descriptionKm: null,
        leadLogoId: null,
        leadDescriptionEn: null,
        leadDescriptionKm: null,
        isEnable: true,
        partnerContents: [],
        metaTitleEn: null,
        metaDescriptionEn: null,
        metaTitleKm: null,
        metaDescriptionKm: null,
        partnerConsultants: [],
        partnerContractors: [],
      },
      initData: {},
      loading: false,
      type: 2,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    onDragChangeConsultant() {
      this.data.partnerConsultants.forEach((item, index) => {
        item.sequenceOrder = index + 1;
      });
    },
    onMoveConsultant({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChangeConsultant(event, idx) {
      const index = this.data.partnerConsultants.findIndex((element, i) => {
        return idx == i;
      });
      if (index != -1) {
        this.data.partnerConsultants[index].image = event.fileUrl;
      }
    },
    onDragChangeContractor() {
      this.data.partnerContractors.forEach((item, index) => {
        item.sequenceOrder = index + 1;
      });
    },
    onMoveContractor({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    onUploadChangeContractor(event, idx) {
      const index = this.data.partnerContractors.findIndex((element, i) => {
        return idx == i;
      });
      if (index != -1) {
        this.data.partnerContractors[index].image = event.fileUrl;
      }
    },
    show() {
      PartnerRepository.show().then((response) => {
        const data = response?.data?.data;
        if (data) {
          this.initData = {
            ...data,
          };

          data.partnerContents.forEach((item) => {
            if (item.locale == "en") {
              this.data.metaTitleEn = item.metaTitle;
              this.data.metaDescriptionEn = item.metaDescription;
            } else if (item.locale == "km") {
              this.data.metaTitleKm = item.metaTitle;
              this.data.metaDescriptionKm = item.metaDescription;
            }
          });

          this.initData.partnerConsultants.forEach((consultant) => {
            consultant.id = this.consultantIndex++;
          });

          this.initData.partnerContractors.forEach((consultant) => {
            consultant.id = this.contractorIndex++;
          });
        }
      });
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          type = this.type;
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;

      this.data.partnerContents = [
        {
          locale: "en",
          metaTitle: this.data.metaTitleEn,
          metaDescription: this.data.metaDescriptionEn,
        },
        {
          locale: "km",
          metaTitle: this.data.metaTitleKm,
          metaDescription: this.data.metaDescriptionKm,
        },
      ];

      PartnerRepository.update(this.data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    addMoreConsultant() {
      this.consultantIndex++;
      let consultant = {
        id: this.consultantIndex,
        imageId: null,
        image: "",
        sequenceOrder: this.consultantIndex,
      };
      this.data.partnerConsultants.push(consultant);
    },
    removeConsultant(index) {
      this.data.partnerConsultants.splice(index, 1);
      this.consultantKey++;
      this.consultantIndex--;
      this.onDragChangeConsultant();
    },
    addMoreContractor() {
      this.contractorIndex++;
      let contractor = {
        id: this.contractorIndex,
        imageId: null,
        image: "",
        sequenceOrder: this.contractorIndex,
      };
      this.data.partnerContractors.push(contractor);
    },
    removeContractor(index) {
      this.data.partnerContractors.splice(index, 1);
      this.contractorKey++;
      this.contractorIndex--;
      this.onDragChangeContractor();
    },
    back() {
      this.$router.push({
        name: `update-${this.route}`,
      });
    },
  },
  setup() {
    const fields = FormInput;
    const resource = "partner";
    const route = "partner";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>